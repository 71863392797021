import React from 'react'
import './LoginAdmin.scss'
import {LoginForm} from '../../../components/Admin/LoginForm'
import { Container } from 'semantic-ui-react'
export function LoginAdmin() {
  return (
    <Container>
    <div className='login-admin'>
      <div className='login-admin__content'>
      <h1 className='h1-login-form'>Bienvenido de vuelta,</h1>
      <h2 className='h2-login-form'> por favor ingrese sus datos de cuenta.</h2>
        <LoginForm/>
      </div>
    
    </div>
    <footer>
      <p style={{
        position:'absolute',
        bottom:'0',
        right:'0',
        padding:'10px'
      }}>v1.2.14</p>
    </footer>
    </Container>
  )
}
